
import { defineComponent } from '@nuxtjs/composition-api'
import { truncate } from '~/assets/util'

export default defineComponent({
  props: {
    url: { type: String, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    imgAlt: { type: String, required: false, default: undefined },
  },
  setup() {
    return { truncate }
  },
})
